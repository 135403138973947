import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "../../components/UI/Common"
import Container from "react-bootstrap/Container"
import ArrowLeft from "../../images/icon-arrow-nav-left.inline.svg"
import * as style from "./SinglePOST.module.scss"

export const fragment = graphql`
  fragment postContentFragment on WpPost_Postslayouts_PostObject_PostContent {
    postTitle
    blurb
    postBody {
      sectionHeading
      text
    }
  }
`

const SinglePOST = ({
  breadcrumbData,
  post
}) => {
  const {
    uri,
    date,
    postsLayouts: { postObject },
  } = post || {}

  const { postTitle, blurb, postBody } = postObject[0]

  const parsedDate = new Date(`${date}Z`)
  const formattedDate = parsedDate.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  const Related_Posts_QUERY = graphql`
    query GET_RELATED_POST_ITEMS {

       allWpPost(limit: 4) {
          nodes {
            ...PostDataQuery
          }
        }

    }
  `

  const data = useStaticQuery(Related_Posts_QUERY)
  const relatedPostsData = data.allWpPost.nodes
  const filteredRelatedPosts = relatedPostsData.filter(post => post.uri !== uri)
  const relatedPosts = filteredRelatedPosts.slice(0, 3)

  const listingPageLink = breadcrumbData[breadcrumbData.length - 2] ? breadcrumbData[breadcrumbData.length - 2].path : ""

  return (
    <>
      <div className={style.componentWrapper}>
        <div className={style.blogLinkWrapper}>
          <ArrowLeft className={style.arrowIcon} />
          <Link link={listingPageLink}>Back To Articles</Link>
        </div>
        <div className={style.articleWrapper}>
          <article className={style.article}>
            <header className={style.postHeader}>
              <p
                className={style.postDate}
                dangerouslySetInnerHTML={{ __html: formattedDate }}
              />
              <h1
                className={style.postTitle}
                dangerouslySetInnerHTML={{ __html: postTitle }}
              />
            </header>
            {/* {mainImage && (
              <GatsbyImage
                className={style.mainImage}
                image={getImage(mainImage?.localFile)}
                alt={mainImage.altText ? mainImage.altText : "Main post image"}
              />
            )} */}
            <Container fluid={true} className={style.container}>
              {blurb && (
                <div
                  className="my-5 lead"
                  dangerouslySetInnerHTML={{ __html: blurb }}
                />
              )}

              {postBody &&
                postBody.map((content, i) => {
                  const { sectionHeading, text } = content

                  return (
                    <>
                      <div key={i} className="position-relative">
                        {sectionHeading && (
                          <h2
                            className="mt-5 mb-4 text-uppercase"
                            dangerouslySetInnerHTML={{
                              __html: sectionHeading,
                            }}
                          />
                        )}
                        {/* {sectionImage && (
                          <figure className="my-5">
                            <GatsbyImage
                              className={style.bodyImage}
                              image={getImage(sectionImage?.localFile)}
                              alt={
                                sectionImage.altText
                                  ? sectionImage.altText
                                  : "Section image"
                              }
                            />
                            <figcaption
                              className={style.imageCaption}
                              dangerouslySetInnerHTML={{
                                __html: sectionImage.caption,
                              }}
                            />
                          </figure>
                        )} */}

                        {text && (
                          <div
                            className={style.text}
                            dangerouslySetInnerHTML={{
                              __html: text,
                            }}
                          />
                        )}
                      </div>
                    </>
                  )
                })}
            </Container>
          </article>
        </div>
      </div>
    </>
  )
}

export default SinglePOST
