// extracted by mini-css-extract-plugin
export var arrowIcon = "SinglePOST-module--arrowIcon--f70e9";
export var article = "SinglePOST-module--article--67fed";
export var articleWrapper = "SinglePOST-module--articleWrapper--f06ff";
export var bgGrey100 = "SinglePOST-module--bg-grey-100--be902";
export var bgGrey150 = "SinglePOST-module--bg-grey-150--0cc46";
export var bgGrey200 = "SinglePOST-module--bg-grey-200--f363a";
export var bgGrey300 = "SinglePOST-module--bg-grey-300--dbf50";
export var bgGrey400 = "SinglePOST-module--bg-grey-400--1b03a";
export var bgGrey500 = "SinglePOST-module--bg-grey-500--b7298";
export var bgGrey600 = "SinglePOST-module--bg-grey-600--20e75";
export var bgGrey700 = "SinglePOST-module--bg-grey-700--05fc7";
export var bgGrey800 = "SinglePOST-module--bg-grey-800--bd086";
export var bgGrey900 = "SinglePOST-module--bg-grey-900--688cf";
export var blogLinkWrapper = "SinglePOST-module--blogLinkWrapper--e60f4";
export var bodyImage = "SinglePOST-module--bodyImage--c8519";
export var componentWrapper = "SinglePOST-module--componentWrapper--e75c9";
export var container = "SinglePOST-module--container--f063d";
export var greyLine = "SinglePOST-module--greyLine--42f65";
export var imageCaption = "SinglePOST-module--imageCaption--42c27";
export var mainImage = "SinglePOST-module--mainImage--711d9";
export var postDate = "SinglePOST-module--postDate--f898b";
export var postHeader = "SinglePOST-module--postHeader--77562";
export var postTitle = "SinglePOST-module--postTitle--a8944";
export var relatedPostsWrapper = "SinglePOST-module--relatedPostsWrapper--acf07";
export var text = "SinglePOST-module--text--6d801";
export var textGrey100 = "SinglePOST-module--text-grey-100--a301d";
export var textGrey150 = "SinglePOST-module--text-grey-150--1bc9d";
export var textGrey200 = "SinglePOST-module--text-grey-200--6225a";
export var textGrey300 = "SinglePOST-module--text-grey-300--aed8c";
export var textGrey400 = "SinglePOST-module--text-grey-400--f773a";
export var textGrey500 = "SinglePOST-module--text-grey-500--09dbf";
export var textGrey600 = "SinglePOST-module--text-grey-600--14400";
export var textGrey700 = "SinglePOST-module--text-grey-700--0e96a";
export var textGrey800 = "SinglePOST-module--text-grey-800--e0cf9";
export var textGrey900 = "SinglePOST-module--text-grey-900--6197d";